.container {
  cursor: crosshair;
  transition: all 1s ease-out;
}

.container:hover {
  transform: scale(1.12);
}

.trailsText {
  position: relative;
  width: 380px;
  height: 110px;
  font-size: 9em;
  line-height: 80px;
  color: white;
  font-weight: bold;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
  text-shadow: -1px -4px 8px rgba(0, 0, 0, 0.25);
  transition: all 1s ease-out;
  white-space: nowrap;
  /* border: 1px solid red; */
}

.trailsText>div {
  padding-right: 0.05em;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  .trailsText {
    width: 340px;
    height: 90px;
    font-size: 8em;
    line-height: 70px;
    transition: all 1s ease-out;
  }
}

@media screen and (max-width: 800px) {
  .trailsText {
    width: 300px;
    height: 85px;
    font-size: 7em;
    line-height: 70px;
    transition: all 1s ease-out;
  }
}

@media screen and (max-height: 600px) {
  .trailsText {
    width: 280px;
    height: 85px;
    font-size: 6.5em;
    line-height: 70px;
    transition: all 1s ease-out;
  }
}

@media screen and (max-height: 540px) {
  .trailsText {
    width: 260px;
    height: 75px;
    font-size: 6em;
    line-height: 70px;
    transition: all 1s ease-out;
  }
}

@media screen and (max-height: 480px) {
  .trailsText {
    width: 240px;
    height: 70px;
    font-size: 5.5em;
    line-height: 75px;
    transition: all 1s ease-out;
  }
}