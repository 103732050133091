.slideHome {
  background: linear-gradient(180deg, #000E32 0%, rgba(6, 19, 54, 0.973871) 15.16%, rgba(10, 24, 58, 0.9525) 18.08%, rgba(39, 51, 80, 0.82) 36.16%, rgba(62, 72, 97, 0.716285) 54.82%, rgba(78, 87, 110, 0.641739) 68.23%, rgba(217, 217, 217, 0) 116.64%);
  background-color: white;
  height: 100vh;
}

.slideOther {
  background: linear-gradient(180deg, #E5E6E9 0%, rgba(39, 51, 80, 0) 100%);
  height: 100%;
  transition: all 1s ease-out;
}